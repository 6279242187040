import { isElectron } from "react-device-detect";
import { Logger } from "utils/Logger";
import { ElectronInterop as ElectronInteropType } from "gather-electron-interop/dist/src/public/ElectronInterop";

declare global {
  interface Window {
    _electron_interop?: ElectronInteropType;
  }
}

let ElectronInterop = window._electron_interop;

if (!ElectronInterop) {
  try {
    ElectronInterop = window.parent._electron_interop;
  } catch {
    Logger.error("Error trying to access parent window.");
  }
}

if (isElectron && !ElectronInterop) {
  // window._electron_interop not found - something is potentially wrong with the interop file!
  const message =
    "A fatal error occurred! You may need to restart your app to update it. Please contact us if the issue persists.";
  alert(message); // nosemgrep
  Logger.warn(message);
}

export default ElectronInterop;
