import React from "react";
import { ThemeProvider } from "@emotion/react";
import { DARK_THEME } from "styles/themes";
import Layout from "components/ui/Layout";
import { COLORS } from "../styles/themes/colors";
import LoadingScreen from "components/ui/LoadingScreen";
import { Translated } from "../i18n/t";

interface Props {
  loadingText?: Translated;
}

const RootRouteLoadingFallback: React.FC<Props> = React.memo(function RootRouteLoadingFallback({
  loadingText,
}) {
  return (
    <ThemeProvider theme={DARK_THEME}>
      <Layout
        width="100vw"
        height="100%"
        overflow="hidden"
        position="relative"
        backgroundColor={COLORS.DARK1}
      >
        <LoadingScreen loadingText={loadingText} />
      </Layout>
    </ThemeProvider>
  );
});

export default RootRouteLoadingFallback;
