import { useCallback, useEffect } from "react";
import { newRelicManager } from "gather-browser-newrelic-manager/dist/src/public/newRelicManager";
import { Logger } from "utils/Logger";

export enum MetricName {
  AppLoaded = "app-loaded",
  HomeSpacesLoaded = "home-spaces-loaded",
  SpaceInitialized = "space-initialized",
  SpaceLoaderDone = "spaceLoader",
  SpaceLoaderConnectionReady = "connection",
  SpaceLoaderMapsLoaded = "maps",
  SpaceLoaderAvatarsLoaded = "avatars",
  SpaceLoaderObjectsLoaded = "objects",
  CharacterSelectionLoaded = "character-selection-loaded",
  ObjectPickerLoaded = "object-picker-loaded",
  MapmakerObjectPickerLoaded = "mapmaker-object-picker-loaded",
  UserIdLoaded = "user-id-loaded",
  RecordingClientManagerLoaded = "recording-client-manager-loaded",
  RecordingClientLoadWalltime = "recording-client-load-wall-time",
}

type UseMetricOptions = {
  metricName: MetricName;
  autoStart?: boolean;
};

// https://developer.mozilla.org/en-US/docs/Web/API/Performance/mark
export function markMetricStart(metricName: MetricName) {
  clearMetricMeasurements(metricName);
  performance.mark(`start-${metricName}`);
}

export function markMetricEnd(metricName: MetricName) {
  performance.mark(`end-${metricName}`);
}

export function clearMetricMeasurements(metricName: MetricName) {
  performance.clearMarks(`start-${metricName}`);
  performance.clearMarks(`end-${metricName}`);
  performance.clearMeasures(metricName);
}

export function reportMetricMeasurements(metricName: MetricName, entries: PerformanceEntryList) {
  newRelicManager.addPageAction(metricName, { duration: entries[0]?.duration ?? -1 });
}

// https://developer.mozilla.org/en-US/docs/Web/API/Performance/measure
export function measureMetric(
  metricName: MetricName,
  reportMeasurements = true,
  clearMeasurements = true,
  emitMissingEntriesWarning = false,
) {
  const hasStartEntries = performance.getEntriesByName(`start-${metricName}`).length > 0;
  const hasEndEntries = performance.getEntriesByName(`end-${metricName}`).length > 0;

  if (!hasStartEntries || !hasEndEntries) {
    if (emitMissingEntriesWarning) {
      Logger.warn("Missing performance entries for ", metricName);
    }

    return;
  }

  performance.measure(metricName, `start-${metricName}`, `end-${metricName}`);
  const measurements = performance.getEntriesByName(metricName);

  if (reportMeasurements) {
    reportMetricMeasurements(metricName, measurements);
  }
  if (clearMeasurements) {
    clearMetricMeasurements(metricName);
  }
  return measurements;
}

export function useDurationMetric({ metricName, autoStart = true }: UseMetricOptions) {
  const startTrackingMetric = useCallback(() => {
    markMetricStart(metricName);
  }, [metricName]);

  const stopTrackingMetric = useCallback(() => {
    markMetricEnd(metricName);
    measureMetric(metricName);
  }, [metricName]);

  useEffect(() => {
    if (autoStart) {
      startTrackingMetric();
    }
  }, [autoStart, startTrackingMetric]);

  return { startTrackingMetric, stopTrackingMetric };
}
