import React from "react";
import styled from "@emotion/styled";
import { Layers } from "styles/positioning/Layers";
import { css } from "@emotion/react";

const StyledBackdrop = styled.div<{ zIndex: number; coverScreen: boolean }>`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: ${({ zIndex }) => zIndex};
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ coverScreen }) =>
    coverScreen &&
    css`
      position: fixed;
      inset: 0;
    `}
`;

interface Props {
  zIndex?: number;
  className?: string;
  coverScreen?: boolean;
}

// Lint warning auto-ignored when enabling the require-memo rule.
// TODO: PP-820 Remove these comments and correctly memo this component ASAP! See GameComponent for an example.
// This may be a false positive - see https://github.com/gathertown/gather-town/pull/11048#issuecomment-1251676518 if so.
// eslint-disable-next-line @gathertown/require-memo
const ModalBackdrop: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  zIndex = Layers.Modal,
  className,
  coverScreen = false,
}) => (
  <StyledBackdrop zIndex={zIndex} className={className} coverScreen={coverScreen}>
    {children}
  </StyledBackdrop>
);

export default ModalBackdrop;
