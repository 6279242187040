import { StopRecording_Reason } from "@gathertown/gather-game-common/dist/src/public/generated_DO_NOT_TOUCH/events";
import { ClientType } from "gather-common/dist/src/public/constants";

export const MAXIMUM_RECORDING_DURATION = 3 * 60 * 60 * 1000; // 3 hours
const WARNING_TIME = 10 * 60 * 1000; // 10 minute
export const MAXIMUM_RECORDING_WARNING = MAXIMUM_RECORDING_DURATION - WARNING_TIME;

export const NO_VIDEOS_DETECTED_THRESHOLD_MS = 2 * 60_000;

export const isRecordingMode = () => {
  const query = new URLSearchParams(window.location.search);
  return query.get("clientType") === ClientType.Recorder;
};

export const getNoVideosDetectedReason = (
  initiatedAtMs?: number,
  initThreshold = NO_VIDEOS_DETECTED_THRESHOLD_MS,
) => {
  const timeSinceInitiated = initiatedAtMs ? Date.now() - initiatedAtMs : 0;
  return timeSinceInitiated < initThreshold
    ? StopRecording_Reason.NoVideosDetectedOnStart
    : StopRecording_Reason.NoVideosDetected;
};
