import { blackenColor, COLORS, colorWithAlpha, darkenColor, whitenColor } from "./colors";

export const DARK_THEME = {
  themeName: "DARK_THEME",
  button: {
    primary: {
      default: {
        foregroundColor: COLORS.DARK2,
        backgroundColor: COLORS.GREEN,
        borderColor: "transparent",
      },
      active: {
        foregroundColor: COLORS.DARK2,
        backgroundColor: blackenColor(COLORS.GREEN, 10),
        borderColor: "transparent",
      },
      focus: {
        foregroundColor: COLORS.DARK2,
        backgroundColor: whitenColor(COLORS.GREEN, 40),
        borderColor: "transparent",
      },
      hover: {
        foregroundColor: COLORS.DARK2,
        backgroundColor: whitenColor(COLORS.GREEN, 30),
        borderColor: "transparent",
      },
      disabled: {
        foregroundColor: COLORS.DARK2,
        backgroundColor: COLORS.GREEN,
        borderColor: "transparent",
      },
      selected: {
        foregroundColor: COLORS.DARK2,
        backgroundColor: blackenColor(COLORS.GREEN, 10),
        borderColor: "transparent",
      },
    },
    secondary: {
      default: {
        foregroundColor: COLORS.GREEN,
        backgroundColor: "transparent",
        borderColor: COLORS.GREEN,
      },
      active: {
        foregroundColor: blackenColor(COLORS.GREEN, 10),
        backgroundColor: "transparent",
        borderColor: blackenColor(COLORS.GREEN, 10),
      },
      focus: {
        foregroundColor: whitenColor(COLORS.GREEN, 40),
        backgroundColor: "transparent",
        borderColor: whitenColor(COLORS.GREEN, 40),
      },
      hover: {
        foregroundColor: whitenColor(COLORS.GREEN, 30),
        backgroundColor: "transparent",
        borderColor: whitenColor(COLORS.GREEN, 30),
      },
      disabled: {
        foregroundColor: COLORS.GREEN,
        backgroundColor: "transparent",
        borderColor: COLORS.GREEN,
      },
      selected: {
        foregroundColor: blackenColor(COLORS.GREEN, 10),
        backgroundColor: "transparent",
        borderColor: blackenColor(COLORS.GREEN, 10),
      },
    },
    tertiary: {
      default: {
        foregroundColor: COLORS.GRAY0,
        backgroundColor: COLORS.BLUE3,
        borderColor: "transparent",
      },
      active: {
        foregroundColor: COLORS.GRAY0,
        backgroundColor: blackenColor(COLORS.BLUE3, 10),
        borderColor: "transparent",
      },
      focus: {
        foregroundColor: COLORS.GRAY0,
        backgroundColor: whitenColor(COLORS.BLUE3, 30),
        borderColor: "transparent",
      },
      hover: {
        foregroundColor: COLORS.GRAY0,
        backgroundColor: whitenColor(COLORS.BLUE3, 20),
        borderColor: "transparent",
      },
      disabled: {
        foregroundColor: COLORS.GRAY0,
        backgroundColor: COLORS.BLUE3,
        borderColor: "transparent",
      },
      selected: {
        foregroundColor: COLORS.GRAY0,
        backgroundColor: blackenColor(COLORS.BLUE3, 10),
        borderColor: "transparent",
      },
    },
    "low-key": {
      default: {
        foregroundColor: COLORS.WHITE,
        backgroundColor: COLORS.DARK0,
        borderColor: "transparent",
      },
      active: {
        foregroundColor: COLORS.WHITE,
        backgroundColor: blackenColor(COLORS.DARK0, 10),
        borderColor: "transparent",
      },
      focus: {
        foregroundColor: COLORS.WHITE,
        backgroundColor: whitenColor(COLORS.DARK0, 30),
        borderColor: "transparent",
      },
      hover: {
        foregroundColor: COLORS.WHITE,
        backgroundColor: whitenColor(COLORS.DARK0, 20),
        borderColor: "transparent",
      },
      disabled: {
        foregroundColor: COLORS.WHITE,
        backgroundColor: COLORS.DARK0,
        borderColor: "transparent",
      },
      selected: {
        foregroundColor: COLORS.WHITE,
        backgroundColor: blackenColor(COLORS.DARK0, 10),
        borderColor: "transparent",
      },
    },
    ghost: {
      default: {
        foregroundColor: COLORS.WHITE,
        backgroundColor: "transparent",
        borderColor: "transparent",
      },
      active: {
        foregroundColor: COLORS.WHITE,
        backgroundColor: blackenColor(COLORS.DARK0, 10),
        borderColor: "transparent",
      },
      focus: {
        foregroundColor: COLORS.WHITE,
        backgroundColor: colorWithAlpha(COLORS.DARK0, 30),
        borderColor: "transparent",
      },
      hover: {
        foregroundColor: COLORS.WHITE,
        backgroundColor: colorWithAlpha(COLORS.DARK0, 30),
        borderColor: "transparent",
      },
      disabled: {
        foregroundColor: COLORS.WHITE,
        backgroundColor: "transparent",
        borderColor: "transparent",
      },
      selected: {
        foregroundColor: COLORS.WHITE,
        backgroundColor: blackenColor(COLORS.DARK0, 10),
        borderColor: "transparent",
      },
    },
    danger: {
      default: {
        foregroundColor: COLORS.GRAY0,
        backgroundColor: COLORS.RED2,
        borderColor: "transparent",
      },
      active: {
        foregroundColor: COLORS.GRAY0,
        backgroundColor: blackenColor(COLORS.RED2, 10),
        borderColor: "transparent",
      },
      focus: {
        foregroundColor: COLORS.GRAY0,
        backgroundColor: whitenColor(COLORS.RED2, 30),
        borderColor: "transparent",
      },
      hover: {
        foregroundColor: COLORS.GRAY0,
        backgroundColor: whitenColor(COLORS.RED2, 20),
        borderColor: "transparent",
      },
      disabled: {
        foregroundColor: COLORS.GRAY0,
        backgroundColor: COLORS.RED2,
        borderColor: "transparent",
      },
      selected: {
        foregroundColor: COLORS.GRAY0,
        backgroundColor: blackenColor(COLORS.RED2, 10),
        borderColor: "transparent",
      },
    },
    "danger-secondary": {
      default: {
        foregroundColor: COLORS.GRAY0,
        backgroundColor: COLORS.RED2,
        borderColor: "transparent",
      },
      active: {
        foregroundColor: COLORS.GRAY0,
        backgroundColor: blackenColor(COLORS.RED2, 10),
        borderColor: "transparent",
      },
      focus: {
        foregroundColor: COLORS.GRAY0,
        backgroundColor: whitenColor(COLORS.RED2, 30),
        borderColor: "transparent",
      },
      hover: {
        foregroundColor: COLORS.GRAY0,
        backgroundColor: whitenColor(COLORS.RED2, 20),
        borderColor: "transparent",
      },
      disabled: {
        foregroundColor: COLORS.GRAY0,
        backgroundColor: COLORS.RED2,
        borderColor: "transparent",
      },
      selected: {
        foregroundColor: COLORS.GRAY0,
        backgroundColor: blackenColor(COLORS.RED2, 10),
        borderColor: "transparent",
      },
    },
    "inline-text": {
      default: {
        foregroundColor: COLORS.WHITE,
        backgroundColor: "transparent",
        borderColor: "transparent",
      },
      active: {
        foregroundColor: COLORS.WHITE,
        backgroundColor: "transparent",
        borderColor: "transparent",
      },
      focus: {
        foregroundColor: COLORS.WHITE,
        backgroundColor: "transparent",
        borderColor: "transparent",
      },
      hover: {
        foregroundColor: COLORS.WHITE,
        backgroundColor: "transparent",
        borderColor: "transparent",
      },
      disabled: {
        foregroundColor: COLORS.WHITE,
        backgroundColor: "transparent",
        borderColor: "transparent",
      },
      selected: {
        foregroundColor: COLORS.WHITE,
        backgroundColor: "transparent",
        borderColor: "transparent",
      },
    },
    "inline-text-danger": {
      default: {
        foregroundColor: COLORS.RED2,
        backgroundColor: "transparent",
        borderColor: "transparent",
      },
      active: {
        foregroundColor: blackenColor(COLORS.RED2, 10),
        backgroundColor: "transparent",
        borderColor: "transparent",
      },
      focus: {
        foregroundColor: blackenColor(COLORS.RED2, 30),
        backgroundColor: "transparent",
        borderColor: "transparent",
      },
      hover: {
        foregroundColor: blackenColor(COLORS.RED2, 20),
        backgroundColor: "transparent",
        borderColor: "transparent",
      },
      disabled: {
        foregroundColor: COLORS.RED2,
        backgroundColor: "transparent",
        borderColor: "transparent",
      },
      selected: {
        foregroundColor: COLORS.RED2,
        backgroundColor: "transparent",
        borderColor: "transparent",
      },
    },
    overlay: {
      default: {
        foregroundColor: COLORS.WHITE,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        borderColor: "rgba(255, 255, 255, 0.2)",
      },
      active: {
        foregroundColor: COLORS.WHITE,
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        borderColor: "rgba(255, 255, 255, 0.4)",
      },
      focus: {
        foregroundColor: COLORS.WHITE,
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        borderColor: "rgba(255, 255, 255, 0.4)",
      },
      hover: {
        foregroundColor: COLORS.WHITE,
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        borderColor: "rgba(255, 255, 255, 0.4)",
      },
      disabled: {
        foregroundColor: COLORS.WHITE,
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        borderColor: "rgba(255, 255, 255, 0.4)",
      },
      selected: {
        foregroundColor: COLORS.WHITE,
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        borderColor: "rgba(255, 255, 255, 0.4)",
      },
    },
    white: {
      default: {
        foregroundColor: COLORS.DARK2,
        backgroundColor: COLORS.GRAY0,
        borderColor: COLORS.GRAY0,
      },
      active: {
        foregroundColor: COLORS.DARK2,
        backgroundColor: blackenColor(COLORS.GRAY0, 10),
        borderColor: blackenColor(COLORS.GRAY0, 10),
      },
      focus: {
        foregroundColor: COLORS.DARK2,
        backgroundColor: blackenColor(COLORS.GRAY0, 10),
        borderColor: blackenColor(COLORS.GRAY0, 10),
      },
      hover: {
        foregroundColor: COLORS.DARK2,
        backgroundColor: blackenColor(COLORS.GRAY0, 10),
        borderColor: blackenColor(COLORS.GRAY0, 10),
      },
      disabled: {
        foregroundColor: COLORS.DARK2,
        backgroundColor: COLORS.GRAY0,
        borderColor: COLORS.GRAY0,
      },
      selected: {
        foregroundColor: COLORS.DARK2,
        backgroundColor: blackenColor(COLORS.GRAY0, 10),
        borderColor: blackenColor(COLORS.GRAY0, 10),
      },
    },
    "insights-primary": {
      default: {
        foregroundColor: COLORS.GRAY0,
        backgroundColor: COLORS.BLUE3,
        borderColor: "transparent",
        background: "linear-gradient(180deg, #7b67ea 0%, #5f4dc5 100%)",
      },
      active: {
        foregroundColor: COLORS.GRAY0,
        backgroundColor: blackenColor(COLORS.BLUE3, 10),
        borderColor: "transparent",
        background: "linear-gradient(180deg, #7b67ea 0%, #5f4dc5 100%)",
      },
      focus: {
        foregroundColor: COLORS.GRAY0,
        backgroundColor: whitenColor(COLORS.BLUE3, 30),
        borderColor: "transparent",
        background: "linear-gradient(180deg, #7b67ea 0%, #5f4dc5 100%)",
      },
      hover: {
        foregroundColor: COLORS.GRAY0,
        backgroundColor: whitenColor(COLORS.BLUE3, 20),
        borderColor: "transparent",
        background: "linear-gradient(180deg, #7b67ea 0%, #5f4dc5 100%)",
      },
      disabled: {
        foregroundColor: COLORS.GRAY0,
        backgroundColor: COLORS.BLUE3,
        borderColor: "transparent",
        background: "linear-gradient(180deg, #7b67ea 0%, #5f4dc5 100%)",
      },
      selected: {
        foregroundColor: COLORS.GRAY0,
        backgroundColor: blackenColor(COLORS.BLUE3, 10),
        borderColor: "transparent",
        background: "linear-gradient(180deg, #7b67ea 0%, #5f4dc5 100%)",
      },
    },
    "insights-secondary": {
      default: {
        foregroundColor: COLORS.GRAY0,
        backgroundColor: colorWithAlpha(COLORS.WHITE, 0.2),
        borderColor: "transparent",
      },
      active: {
        foregroundColor: COLORS.GRAY0,
        backgroundColor: blackenColor(colorWithAlpha(COLORS.WHITE, 0.2), 10),
        borderColor: "transparent",
      },
      focus: {
        foregroundColor: COLORS.GRAY0,
        backgroundColor: darkenColor(colorWithAlpha(COLORS.WHITE, 0.2), 30),
        borderColor: "transparent",
      },
      hover: {
        foregroundColor: COLORS.GRAY0,
        backgroundColor: darkenColor(colorWithAlpha(COLORS.WHITE, 0.2), 20),
        borderColor: "transparent",
      },
      disabled: {
        foregroundColor: COLORS.GRAY0,
        backgroundColor: colorWithAlpha(COLORS.WHITE, 0.2),
        borderColor: "transparent",
      },
      selected: {
        foregroundColor: COLORS.GRAY0,
        backgroundColor: blackenColor(colorWithAlpha(COLORS.WHITE, 0.2), 10),
        borderColor: "transparent",
      },
    },
    "insights-tertiary": {
      default: {
        foregroundColor: colorWithAlpha(COLORS.BLACK, 0.6),
        backgroundColor: colorWithAlpha(COLORS.BLACK, 0.1),
        borderColor: "transparent",
      },
      active: {
        foregroundColor: colorWithAlpha(COLORS.BLACK, 0.6),
        backgroundColor: colorWithAlpha(whitenColor(COLORS.BLACK, 20), 0.1),
        borderColor: "transparent",
      },
      focus: {
        foregroundColor: colorWithAlpha(COLORS.BLACK, 0.6),
        backgroundColor: colorWithAlpha(whitenColor(COLORS.BLACK, 40), 0.1),
        borderColor: "transparent",
      },
      hover: {
        foregroundColor: colorWithAlpha(COLORS.BLACK, 0.6),
        backgroundColor: colorWithAlpha(whitenColor(COLORS.BLACK, 30), 0.1),
        borderColor: "transparent",
      },
      disabled: {
        foregroundColor: colorWithAlpha(COLORS.BLACK, 0.6),
        backgroundColor: colorWithAlpha(COLORS.WHITE, 0.2),
        borderColor: "transparent",
      },
      selected: {
        foregroundColor: colorWithAlpha(COLORS.BLACK, 0.6),
        backgroundColor: colorWithAlpha(whitenColor(COLORS.BLACK, 20), 0.1),
        borderColor: "transparent",
      },
    },
    setting: {
      default: {
        foregroundColor: COLORS.WHITE,
        backgroundColor: colorWithAlpha(COLORS.WHITE, 0.2),
        borderColor: "transparent",
      },
      active: {
        foregroundColor: COLORS.WHITE,
        backgroundColor: colorWithAlpha(COLORS.WHITE, 0.1),
        borderColor: "transparent",
      },
      focus: {
        foregroundColor: COLORS.WHITE,
        backgroundColor: colorWithAlpha(COLORS.WHITE, 0.1),
        borderColor: "transparent",
      },
      hover: {
        foregroundColor: COLORS.WHITE,
        backgroundColor: colorWithAlpha(COLORS.WHITE, 0.15),
        borderColor: "transparent",
      },
      disabled: {
        foregroundColor: COLORS.WHITE,
        backgroundColor: colorWithAlpha(COLORS.WHITE, 0.1),
        borderColor: "transparent",
      },
      selected: {
        foregroundColor: COLORS.WHITE,
        backgroundColor: colorWithAlpha(COLORS.WHITE, 0.1),
        borderColor: "transparent",
      },
    },
  },
  switch: {
    standard: {
      checked: {
        backgroundColor: COLORS.GREEN,
      },
      unchecked: {
        backgroundColor: "#909ce2",
      },
      disabled: {
        backgroundColor: colorWithAlpha(COLORS.GRAY3, 0.5),
      },
      loading: {
        backgroundColor: COLORS.GRAY3,
      },
    },
    monotone: {
      checked: {
        backgroundColor: COLORS.BLUE3,
      },
      unchecked: {
        backgroundColor: COLORS.BLUE3,
      },
      disabled: {
        backgroundColor: colorWithAlpha(COLORS.GRAY3, 0.5),
      },
      loading: {
        backgroundColor: COLORS.GRAY3,
      },
    },
  },
  textInput: {
    borderColor: {
      default: "#909CE2",
      focus: "#ECF1FF",
      hover: "#C0C9FE",
      error: COLORS.RED1,
    },
    chip: {
      defaultChipColor: COLORS.DARK1,
      chipTextColor: COLORS.WHITE,
      defaultChipBorder: COLORS.DARK1,
    },
    iconColor: COLORS.GRAY0,
    labelTextColor: COLORS.GRAY0,
    textColor: COLORS.GRAY0,
    placeholderTextColor: COLORS.GRAY4,
    errorTextColor: COLORS.RED0,
  },
  text: {
    primary: COLORS.WHITE,
    secondary: COLORS.GRAY2,
    fontFamily: "DM Sans, sans-serif",
  },
  icon: COLORS.WHITE,

  // NOTE (@josh): Don't rely or add to this just yet, as we are still working on theme
  // variable naming with design.
  background: {
    primary: COLORS.DARK3,
    secondary: "rgb(40, 45, 78)",
    highlight: COLORS.DARK0,
  },
  mapmaker: {
    // Just for now :sweat:
    colorSelect: {
      selectedBackground: COLORS.DARK0,
    },
  },
};
