import React, { PropsWithChildren } from "react";
import styled from "@emotion/styled";
import { Interpolation, Theme, useTheme } from "@emotion/react";

import { TEXT_MAP, TextKind, TextProps } from "./Text.utils";

interface StyledTextProps extends TextProps {
  fontFamily: string;
  color: string;
}

const StyledText = styled.span<StyledTextProps>`
  color: ${({ color }) => color};
  font-family: ${({ fontFamily }) => fontFamily};
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: ${({ fontSize }) => `${fontSize}px`};
  line-height: ${({ lineHeight }) => `${lineHeight}px`};
  ${({ letterSpacing }) => letterSpacing && `letter-spacing: ${letterSpacing}em;`}
  ${({ isCapitalized }) => isCapitalized && "text-transform: uppercase;"}

  > strong {
    font-weight: bold;
  }
`;

const StyledLink = styled.a<StyledTextProps>`
  color: ${({ color }) => color};
  font-family: ${({ fontFamily }) => fontFamily};
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: ${({ fontSize }) => `${fontSize}px`};
  line-height: ${({ lineHeight }) => `${lineHeight}px`};
  ${({ letterSpacing }) => letterSpacing && `letter-spacing: ${letterSpacing}em;`}
  ${({ isCapitalized }) => isCapitalized && "text-transform: uppercase;"}
  text-decoration: underline;
  cursor: pointer;

  &:hover,
  &:focus {
    color: ${({ color }) => color};
  }
`;

export interface Props {
  /** Color of text */
  color?: string;
  /** Uses the TEXT_MAP to translate this into the text size */
  kind: TextKind;
  /** Adds external link to text */
  href?: string; // External link only!
  /** How the link should behave when clicking it */
  target?: string;
  /** How information should be sent to the opened link */
  rel?: string;
  // Most styling can be provided through this prop. You can provide css tagged literals, objects, or an array of the
  // two. The follow values for the `style` prop are all valid:
  //
  //    - css`color: blue;`
  //    - { color: 'blue' }
  //    - [css`color: blue;`, { font-size: 40 }]
  //
  // Many "pre-packaged" styles are found in `modules/gather-browser/src/styles/utils/utils.ts`. You are encouraged
  // to reuse those rather than write your own, so we can keep our styles more DRY.
  /** Custom styling that can be provided in regular style object or CSS emotion interpolation  */
  style?: Interpolation<Theme>;
  className?: string;
}

/** Generic text component to be used for any text inside Gather. It normalizes all
 *  text sizes and has multiple features to reduce boilerplate when adding text.
 */
const Text = React.memo<PropsWithChildren<Props>>(function Text({
  color,
  kind,
  children,
  href,
  target,
  style,
  className,
  rel,
}) {
  const theme = useTheme();
  const textProps = TEXT_MAP[kind];

  return href ? (
    <StyledLink
      {...textProps}
      fontFamily={theme.text.fontFamily}
      color={color ?? theme.text.primary}
      css={style}
      href={href}
      target={target}
      rel={rel}
      className={className}
    >
      {children}
    </StyledLink>
  ) : (
    <StyledText
      {...textProps}
      fontFamily={theme.text.fontFamily}
      color={color ?? theme.text.primary}
      css={style}
      className={className}
    >
      {children}
    </StyledText>
  );
});

export default Text;
