import { HttpV2Client } from "gather-browser-common/dist/src/public/http-client/httpV2Client";
import { ClientReleasePriority } from "gather-http-common/dist/src/public/clientReleases";
import {
  CLIENT_REFRESH_CHECK_INTERVAL_MS,
  CLIENT_REFRESH_SERVER_POLLING_INTERVAL_MS,
} from "gather-client-common/dist/src/public/clientStatusUtils";
import { Logger } from "utils/Logger";
import { DateTime } from "luxon";
import {
  browserName,
  browserVersion,
  osName,
  osVersion,
} from "gather-browser-common/dist/src/public/userAgent";
import { getSpaceIdFromPath } from "gather-browser-common/dist/src/public/utils";
import { auth } from "gather-firebase-client/dist/src/public/firebase";
import ClientReleasesController from "game/controllers/ClientReleasesController";
import { HttpV2Paths } from "gather-http-common/dist/src/public/httpAPI";
import { getTrueGates } from "utils/gateLoggingUtils";
import { buildErrorContext } from "utils/console";

const PAGE_LOAD_TIME = DateTime.now().toISO();

let lastServerRequestTime = DateTime.fromMillis(0);

export interface BrowserUpdateInfo {
  broken?: boolean;
  outdated?: boolean;
  level?: ClientReleasePriority;
}

export const resetLastServerRequestTime = () => {
  lastServerRequestTime = DateTime.fromMillis(0);
};

/**
 * Begins polling the client status HTTP endpoint to periodically check if we need to prompt the user to update.
 */
export const monitorClientStatus = () => {
  const interval = setInterval(async () => {
    // If not enough time has elapsed since our last server request, do nothing.
    if (
      DateTime.now().minus({ milliseconds: CLIENT_REFRESH_SERVER_POLLING_INTERVAL_MS }) <
      lastServerRequestTime
    ) {
      return;
    }

    lastServerRequestTime = DateTime.now();

    // Ask the server about our client status.
    try {
      const userId = auth.currentUser?.uid ?? null;
      const spaceId = getSpaceIdFromPath();

      const res = await HttpV2Client.post<BrowserUpdateInfo>(HttpV2Paths.BrowserClientReleases, {
        params: {
          path: {
            browserName,
            releaseCommit: COMMIT_HASH,
            releaseChannel: "latest",
          },
          body: {
            osName,
            osVersion,
            browserVersion,

            buildTimestamp: BUILD_TIMESTAMP,
            pageLoadTimestamp: PAGE_LOAD_TIME,

            userId,
            spaceId,
            gates: getTrueGates(userId, spaceId),
          },
        },
      });

      if (res) {
        ClientReleasesController.handleBrowserClientUpdate(res);
      }
    } catch (e) {
      // Failure isn't a big deal because we run this check frequently - could be a network blip, etc.
      Logger.warn("[Client Status] Check failed!", buildErrorContext(e));
    }
  }, CLIENT_REFRESH_CHECK_INTERVAL_MS);

  return () => {
    clearInterval(interval);
  };
};
