import { HttpV2Client } from "gather-browser-common/dist/src/public/http-client/httpV2Client";
import { authTokenManager } from "gather-auth-client/dist/src/public/auth";
import { newRelicManager } from "gather-browser-newrelic-manager/dist/src/public/newRelicManager";
import { AvStrategy } from "gather-common/dist/src/public/av";
import { RTCStatsManager } from "gather-video-client/dist/src/public/metrics/RTCStatsManager";
import { GatherPeerManager } from "gather-video-client/dist/src/public/strategies/gather/GatherPeerManager";
import { LivekitPeerManager } from "gather-video-client/dist/src/public/strategies/livekit/LivekitPeerManager";
import { videoSystem } from "gather-video-client/dist/src/public/VideoSystem";
import { AVStrategyMap, registerGlobals } from "gather-video-client/dist/src/public/Globals";
import { gate } from "gather-browser-common/dist/src/public/gatekeeping/gate";
import { logMetricsEventSkippingNewRelic } from "gather-browser-common/dist/src/public/metrics/utils";

const avStrategyMap: AVStrategyMap = {
  base: () => undefined,
  gather: (statsManager: RTCStatsManager) => new GatherPeerManager(statsManager),
  livekit: (statsManager: RTCStatsManager) =>
    new LivekitPeerManager(statsManager, { selfhost: false }),
  livekitselfhost: (statsManager: RTCStatsManager) =>
    new LivekitPeerManager(statsManager, { selfhost: true }),
};

const gatherVideoClientDataSource = {
  gate,
  getAVStrategyMap: () => avStrategyMap,
  performance: () => performance,
};

export const registerGatherVideoClientGlobals = () =>
  registerGlobals({
    HttpV2Client,
    authTokenManager,
    metricsManager: newRelicManager,
    eventsManager: { addEvent: logMetricsEventSkippingNewRelic },
    gatherVideoClientDataSource,
  });

export const getCurrentAvStrategySupportsMobile = () => {
  const avStrategy = videoSystem.peerManager.getCurrentAVStrategy();
  return avStrategy === AvStrategy.Livekit || avStrategy === AvStrategy.Gather;
};

// example SFU URL
// wss://ip-10-205-106-64.us-west-1-a.prod.aws.gather.town:443
export const isAwsSfu = (serverUrl: string) =>
  serverUrl.startsWith("wss://ip-") && serverUrl.replace(":443", "").endsWith("aws.gather.town");

// returns { ip: 10.205.106.64, region: us-west-1-a}
export const getSfuFromUrl = (serverUrl: string) => {
  const sfuUrlElements = serverUrl.replace("wss://", "").split(".");
  const ip = sfuUrlElements[0]?.replace("ip-", "")?.replace(/-/g, ".") ?? "N/A";
  const region = sfuUrlElements[1] ?? "N/A";
  return { ip, region };
};

// example Global Accelerator SFU URL:
// wss://sfu.us-east-1-a.prod.aws.gather.town:443/ip-10-205-1-10
export const isAwsGaSfu = (serverUrl: string) =>
  serverUrl.startsWith("wss://sfu.") &&
  serverUrl.replace(":443", "").includes("aws.gather.town/ip-");

// returns {ip: 10.205.106.64, region: us-east-1-a [GA]}
export const getSfuFromGaUrl = (serverUrl: string) => {
  const sfuUrlElements = serverUrl.replace("wss://", "").split("/");
  const dnsElements = sfuUrlElements[0]?.split(".") ?? [];
  const ip = sfuUrlElements[1]?.replace("ip-", "").replace(/-/g, ".") ?? "N/A";
  const region = dnsElements[1]?.concat(" [GA]") ?? "N/A";
  return { ip, region };
};
