import { HttpV2Client } from "gather-browser-common/dist/src/public/http-client/httpV2Client";
import { Logger } from "utils/Logger";
import { HttpV2Paths } from "gather-http-common/dist/src/public/httpAPI";
import {
  CoreRole,
  Space,
  UserHomeSpaceResource,
} from "gather-common/dist/src/public/resources/space";
import { Outfit } from "gather-common/dist/src/public/resources/users";
import { AuthUserResource } from "gather-common/dist/src/public/resources/user";
import { UserMap } from "gather-http-common/dist/src/public/resources/userMaps";
import { SpaceMap } from "premiumDashboard/state/dashboardSlice";
import { indexBy, mapObjIndexed, pipe, prop } from "ramda";
import { buildErrorContext } from "utils/console";
import { SPACE_BACKGROUND_IMAGE_FALLBACK_URL } from "gather-http-common/dist/src/public/spaceConstants";

export async function updateUserData(update: Partial<AuthUserResource>) {
  return HttpV2Client.patch(HttpV2Paths.UserData, {
    auth: true,
    params: {
      body: {
        update,
      },
    },
  });
}

export async function getRecentSpaces() {
  return await HttpV2Client.get<Record<string, UserHomeSpaceResource> | undefined>(
    HttpV2Paths.UserRecentSpaces,
    {
      auth: true,
      suppressErrors: true,
    },
  );
}

export async function getOwnedSpaces() {
  return await HttpV2Client.get<Record<string, UserHomeSpaceResource> | undefined>(
    HttpV2Paths.UserOwnedSpaces,
    {
      auth: true,
      suppressErrors: true,
    },
  );
}

export async function getOwnedMaps() {
  return HttpV2Client.get<Record<string, UserMap>>(HttpV2Paths.UserMaps, {
    auth: true,
    suppressErrors: true,
  });
}

const convertSpaceToSpaceMap = (space: Space) => ({
  name: space.name,
  // defaultRoom.backgroundImagePath should always be defined after the migrations
  image: space.defaultRoom?.backgroundImagePath ?? SPACE_BACKGROUND_IMAGE_FALLBACK_URL,
});

const convertSpacesToSpaceMap = (spaces: Space[]) =>
  pipe(indexBy<Space, string>(prop("id")), mapObjIndexed(convertSpaceToSpaceMap))(spaces);

export async function getOwnedRooms(): Promise<SpaceMap> {
  try {
    const userSpaces = await HttpV2Client.get<Space[]>(HttpV2Paths.UserSpaces, {
      auth: true,
      params: { query: { role: CoreRole.Owner } },
    });
    return convertSpacesToSpaceMap(userSpaces);
  } catch (err) {
    Logger.log("Couldn't get rooms", buildErrorContext(err));
    return {};
  }
}

export async function getUserDataField<T>(field: string) {
  return HttpV2Client.get<T>(HttpV2Paths.UserDataField, {
    auth: true,
    params: {
      path: {
        field,
      },
    },
    suppressErrors: true,
  });
}

export async function getInitialOutfit(styles?: string[]) {
  return HttpV2Client.get<Outfit>(HttpV2Paths.UserInitialOutfit, {
    auth: true,
    params: { query: { styles } },
  });
}

export async function getUserOutfit(spaceId?: string, user?: string) {
  return HttpV2Client.get<Outfit>(HttpV2Paths.UserWearables, {
    auth: true,
    params: { path: user ? { user } : undefined, query: spaceId ? { spaceId } : undefined },
  });
}
