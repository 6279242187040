/* eslint-disable @gathertown/prefer-logger-over-console */

import {
  buildErrorContext,
  ErrorContext,
  isErrorContextAttributes,
} from "gather-common/dist/src/public/utils";
import { isObject } from "lodash";

const originalConsoleError = console.error;

const stringifyArgs = (args: unknown[]): string =>
  args
    .map((arg) => {
      if (typeof arg === "string") return arg;

      if (arg instanceof Error) return arg.message;
      if (arg instanceof PromiseRejectionEvent) return arg.reason?.message;

      return JSON.stringify(arg, undefined, 2);
    })
    .join(" ");

const getError = (params: unknown[]) => {
  const firstError = params.find((param: unknown): param is Error => param instanceof Error);
  const firstUnhandledRejectionError = params.find(
    (param: unknown): param is PromiseRejectionEvent => param instanceof PromiseRejectionEvent,
  );
  return {
    name: firstError?.name || firstUnhandledRejectionError?.type || "UnknownError",
    message: stringifyArgs(["console error:", ...params]),
    stack: firstError?.stack || firstUnhandledRejectionError?.reason?.stack,
  };
};

// gather-game-client doesn't have access to ErrorContext class, we have to handle it here for proper error reporting
const getParamsWithGameClientErrorContext = (params: unknown[]) =>
  params.map((param) =>
    isObject(param) &&
    "_gameClientError" in param &&
    "attributes" in param &&
    isErrorContextAttributes(param.attributes)
      ? new ErrorContext(
          param.attributes,
          "originalError" in param ? param.originalError : undefined,
        )
      : param,
  );

// Capture console.error calls, and send errors to newrelic
console.error = (...params: unknown[]) => {
  params = getParamsWithGameClientErrorContext(params);
  const errorContext = params.find((param): param is ErrorContext => param instanceof ErrorContext);
  const paramsWithoutErrorContext = params.filter((param) => !(param instanceof ErrorContext));
  // We send the thrown error and the original error to try to get the stack trace/error message from one of them
  window.newrelic?.noticeError(
    getError([...paramsWithoutErrorContext, errorContext?.originalError]),
    errorContext?.attributes,
  );
  originalConsoleError(...paramsWithoutErrorContext, errorContext?.originalError);
};

window.addEventListener("unhandledrejection", (e) => {
  console.error(e);
});

export { ErrorContext, buildErrorContext };
